@tailwind base;
@tailwind utilities;
@tailwind screens;

html * {
  font-family: "openDyslexic", sans-serif;
}

/*@media only screen and (min-width: 320px) {
  html {
    font-size: 7px;
  }
}
@media only screen and (min-width: 480px) {
  html {
    font-size: 8px;
  }
} 
@media only screen and (min-width: 640px) {
  html {
    font-size: 10px;
  }
}
@media only screen and (min-width: 768px) {
  html {
    font-size: 12px;
  }
}*/

@media only screen and (min-width: 1024px) {
  html {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1280px) {
  html {
    font-size: 15px;
  }
}
@media only screen and (min-width: 1500px) {
  html {
    font-size: 16px;
  }
}
@media only screen and (min-width: 2000px) {
  html {
    font-size: 18px;
  }
}
